<template>

  <div>

    <producto-add-new
      :is-add-new-producto-sidebar-active.sync="isAddNewProductoSidebarActive"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-card-title>
        <h1 class="mx-5 pt-2">
          Inventario <small>{{ inventarioSelected.titulo }} - {{ new Date(parseInt(inventarioSelected.fecha, 10)).toLocaleDateString() }}</small>
        </h1>
      </b-card-title>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="10"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries of {{ inventarioProductos.length }}</label>
          </b-col>

          <b-col
            cols="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="exportToExcel"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                variant="primary"
                :disabled="false"
                @click="isAddNewProductoSidebarActive = true"
              >
                <span class="text-nowrap">Add Product</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumnsProductosInventario"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Pedido -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="editProducto(data.item)"
            >
              {{ data.item.id }}
            </b-link>
            <small class="text-muted">{{ data.item.sku }}</small>
          </b-media>
        </template>

        <!-- Column: Cliente -->
        <template #cell(size)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="editProducto(data.item)"
            >
              {{ data.item.sizeLength || '?' }} x {{ data.item.sizeWidth || '?' }} x {{ data.item.sizeHeight || '?' }} CM
            </b-link>
            <small class="text-muted">{{ data.item.weight || '?' }} gr.</small>
          </b-media>
        </template>

        <!-- Column: ruta -->
        <template #cell(precio)="data">
          <div class="text-nowrap">
            <span class="text-right font-weight-bolder">{{ data.item.price || '?' }} €</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(unitInStock)="data">
          {{ data.item.unitInStock || '0' }} Uds.
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="inventarioProductos.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <producto-edit
      :producto="productoSelect"
      :is-edit-producto.sync="isEditProducto"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable,
  BDropdown, BDropdownItem, BPagination, BMedia, BLink,
  BCardTitle,
  // BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { paginateArray, sortCompare } from '@/libs/filter-data'
import Ripple from 'vue-ripple-directive'
import ProductoAddNew from '@/views/bimbo/components/ProductoAddNew.vue'
import ProductoEdit from '@/views/bimbo/components/ProductoEdit.vue'
import useProductos from '../useProductos'
// import { export_json_to_excel } from '@/libs/Export2Excel'

export default {
  components: {
    ProductoAddNew,
    ProductoEdit,

    BCard,
    BCardTitle,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Export Excell
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'csv',
    }
  },

  setup() {
    const {
      // Nuevo Producto
      isAddNewProductoSidebarActive,
      // UI
      isEditProducto,
      productoSelect,
      tableColumns,
      tableColumnsProductosInventario,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Datos
      productos,
      productosList,
      itemsFilterProductos,
      itemsFiltereds,
      inventarios,
      inventarioProductos,
      inventarioSelected,
      // methods
      fetchProductos,
      editProducto,
      addProducto,
      exportToExcel,
      fetchInventarios,
    } = useProductos()

    const items = inventarioProductos.value

    return {
      isEditProducto,
      productoSelect,
      // Nuevo Producto
      isAddNewProductoSidebarActive,
      // UI
      tableColumns,
      tableColumnsProductosInventario,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Datos
      productos,
      productosList,
      itemsFilterProductos,
      itemsFiltereds,
      inventarios,
      inventarioProductos,
      items,
      inventarioSelected,
      // methods
      fetchProductos,
      editProducto,
      addProducto,
      exportToExcel,
      fetchInventarios,
    }
  },

  created() {
    this.fetchProductos()
    this.fetchInventarios()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
